//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//



export default {
  data: function () {

    return {
      show: false,
      url:'',
      camiones: []
    }
  },
  created() {
    console.log('create -> [Camiones / eblas]');

    console.log(this.$store.state);

    if (this.$util.isNull(this.$store.state.truck)) {
       this.$router.push("/main/camiones_paneles");
       return;
    }

    this.url= 'https://mos.enaex.com/eblast_'+this.$store.state.truck.ip.split('.')[3]+'/webvisu.htm';

    console.log(this.$store.state.camion);

    this.$store.state.ts.loader.show = false;


    this.show=true;
  },
  computed: {

  },
  methods: {

  }
};
